import SevenDayAverage from '../Components/SevenDayAverage'
import React, { useState, useEffect } from 'react'
import TrendGraph from '../Components/TrendGraph'
import Gauge from '../Components/Gauge'

const SanAntonio = () => {

    const [processedData,setProcessedData] = useState({
        sevenDayAverage:[],
        trendData: [],
        doublingDays:-1,
        sevenDayPositiveTestPercent: 0
    })

    const [positiveData, setPositiveData] = useState({
        sevenDayPositiveTestPercent:0
    })

    // OlD URL: https://opendata.arcgis.com/datasets/94576453349c462598b2569e9d05d84c_0.geojson
    //Data pulled from 
    //https://services.arcgis.com/g1fRTDLeMgspWrYp/arcgis/rest/services/survey123_d41602844227443591be274e00ae9859_stakeholder/FeatureServer/0/query?where=1%3D1&outFields=*&outSR=4326&f=json
    //
    useEffect(() =>{
        fetch('https://services.arcgis.com/g1fRTDLeMgspWrYp/arcgis/rest/services/survey123_d41602844227443591be274e00ae9859_stakeholder/FeatureServer/0/query?where=1%3D1&outFields=*&outSR=4326&f=json')
        .then(response => response.json())
        .then(data => {
            const filteredData = data.features.filter(feature => feature.attributes.reporting_date != null && feature.attributes.total_case_cumulative != null)

            setProcessedData({
                sevenDayAverage:calculateSevenDayAverages(filteredData),
                doublingDays:calculateDoublingRate(filteredData),
                trendData: filterTrendData(filteredData)
            })
        })

        fetch('https://services.arcgis.com/g1fRTDLeMgspWrYp/arcgis/rest/services/vCOVID19_WeeklyLabTesting_Public/FeatureServer/0/query?where=1%3D1&outFields=*&outSR=4326&f=json')
        .then(response => response.json())
        .then(data => {
            const lastResult = data.features[data.features.length-1].attributes.percent_weekly_bexar_county_pos;
            console.log(lastResult)
            setPositiveData({
                sevenDayPositiveTestPercent: lastResult
            })
        })
    },[])

    const calculateSevenDayAverages = (rawData) => {
        var seq = []
        if (rawData ){
            for(var i =6; i<rawData.length; i++){
                if (rawData[i].attributes.reporting_date) {
                    var sevenDaySum = 0
                    for (var j = 0; j < 7; j++) {
                        sevenDaySum+= rawData[i-j].attributes.total_case_daily_change || 0
                    }
                    seq.push({
                        x: convertDate(rawData[i].attributes.reporting_date),
                        y: sevenDaySum/7
                    })
                }
            }
        }
        return seq
    }

    const calculateDoublingRate = (rawData) => {
        if (rawData){
            var cumulativeTotal = rawData[rawData.length-1].attributes.total_case_cumulative
            var doublingDays = 1
            for (var i = rawData.length; i > 0; i--){
                if (rawData[i-1].attributes.total_case_cumulative <= (cumulativeTotal/2)){
                    return doublingDays
                }
                doublingDays++
            }
        }
        return -1
    }
    const filterTrendData = (rawData) => {
        var seq = []
        if (rawData) {
            for (var i=0; i<rawData.length; i++) {
                seq.push({
                    x: convertDate(rawData[i].attributes.reporting_date),
                    y: rawData[i].attributes.total_case_daily_change
                })
            }
        }
        return seq
    }

    const convertDate = (epochDate) => {
        var date =new Date(epochDate)
        return `${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()}`
    }

    return (
        <div>
            <Gauge label="Doubling Days" value={processedData.doublingDays} maxValue="30" />
            <Gauge label="Positive Test Percentage" value={positiveData.sevenDayPositiveTestPercent} maxValue="100"/>
            <SevenDayAverage rawData={processedData.sevenDayAverage} /> 
            <TrendGraph rawData={processedData.trendData} />
        </div>
    )
}

export default SanAntonio