import { ResponsiveScatterPlot } from '@nivo/scatterplot'
import { line } from "d3-shape";
import React, {useState, useEffect,Fragment} from 'react'

import './SevenDayAverage.css'


const TrendGraph = (props) => {
    const [data,setData] = useState([])

    useEffect(() =>{
        //console.log(props.rawData)
        setData([{
            id:'New Cases',
            color:'hsl(313, 70%, 50%)',
            data:props.rawData
        }])
    },[props.rawData])

    
    return (
        <div className="chart">
            <ResponsiveScatterPlot
            data={data}
            margin={{ top: 60, right: 140, bottom: 70, left: 90 }}
            xScale={{ 
                type: 'time',
                format: '%Y/%m/%d',
                useUTC: false,
                precision: 'day',
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{ type: 'linear', min: 0, max: 'auto' }}
            yFormat={function(e){return e+" New Cases"}}
            blendMode="multiply"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                format: '%b %d',
                tickValues: 'every 8 days',
                legend: 'time scale',
                legendOffset: -12,
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'size',
                legendPosition: 'middle',
                legendOffset: -60
            }}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 130,
                    translateY: 0,
                    itemWidth: 100,
                    itemHeight: 12,
                    itemsSpacing: 5,
                    itemDirection: 'left-to-right',
                    symbolSize: 12,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    </div>)
}

export default TrendGraph