import React from 'react';
import './App.css';
import SanAntonio from './Pages/SanAntonio'
import Texas from './Pages/Texas'



function App() {
  return (
    <div className="App">
      <h2>San Antonio Data</h2>
      <SanAntonio />
      <h2>Texas Data</h2>
      <Texas/>
    </div>
  );
}

export default App;
