import React, {useEffect, useState} from 'react'
import SevenDayAverage from '../Components/SevenDayAverage'
import TrendGraph from '../Components/TrendGraph'
import Gauge from '../Components/Gauge'

const Texas = () => {

    const [processedData,setProcessedData] = useState({
        sevenDayAverage:[],
        trendData:[],
        doublingDays:-1
    })

    //Data pulled from 
    //https://services5.arcgis.com/ACaLB9ifngzawspq/arcgis/rest/services/DSHS_COVID19_Cases_Service/FeatureServer/8/query?f=json&where=1%3D1&returnGeometry=false&spatialRel=esriSpatialRelIntersects&outFields=*&orderByFields=Date%20asc&resultOffset=0&resultRecordCount=32000&resultType=standard&cacheHint=true
    //https://services5.arcgis.com/ACaLB9ifngzawspq/arcgis/rest/services/DSHS_COVID19_Cases_Service/FeatureServer/8/query?f=json&where=1%3D1&returnGeometry=false&spatialRel=esriSpatialRelIntersects&outFields=*&orderByFields=Date%20asc&resultOffset=0&resultRecordCount=32000&resultType=standard&cacheHint=true
    useEffect(() =>{
        fetch('https://services5.arcgis.com/ACaLB9ifngzawspq/arcgis/rest/services/TX_DSHS_COVID19_Cases_Service/FeatureServer/2/query?f=json&where=Date%3Etimestamp%20%272020-04-01%2004%3A59%3A59%27&returnGeometry=false&spatialRel=esriSpatialRelIntersects&outFields=*&orderByFields=Date%20asc&resultOffset=0&resultRecordCount=32000&resultType=standard&cacheHint=true')
        .then(response => response.json())
        .then(data => {
            setProcessedData({
                sevenDayAverage:calculateSevenDayAverages(data),
                doublingDays: calculateDoublingRate(data),
                trendData: filterTrendData(data)
            })
        })
    },[])

    const calculateSevenDayAverages = (rawData) => {
        var seq = []
        if (rawData && rawData.features){
            for(var i =6; i<rawData.features.length; i++){
                if (rawData.features[i].attributes.Date) {
                    var sevenDaySum = 0
                    for (var j = 0; j < 7; j++) {
                        sevenDaySum+= rawData.features[i-j].attributes.DailyNewCases || 0
                    }
                    var date = new Date(rawData.features[i].attributes.Date)
                    seq.push({
                        x: `${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()}`,
                        y: sevenDaySum/7
                    })
                }
            }
        }
        return seq
    }

    const calculateDoublingRate = (rawData) => {
        var cumulativeTotal = rawData.features[rawData.features.length-1].attributes.CumulativeCases
        var doublingDays = 1
        for (var i = rawData.features.length; i > 0; i--){
            if (rawData.features[i-1].attributes.CumulativeCases <= (cumulativeTotal/2)){
                return doublingDays
            }
            doublingDays++
        }
        return -1
    }

    const filterTrendData = (rawData) => {
        var seq = []
        if (rawData) {
            for (var i=0; i<rawData.features.length; i++) {
                var date = new Date(rawData.features[i].attributes.Date)
                seq.push({
                    x: `${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()}`,
                    y: rawData.features[i].attributes.DailyNewCases
                })
            }
        }
        return seq
    }

    return (
        <div>
            <Gauge label="Doubling Days" value={processedData.doublingDays} maxValue="30" />
            <SevenDayAverage rawData={processedData.sevenDayAverage} />
            <TrendGraph rawData={processedData.trendData} />
        </div>   
        )
}

export default Texas